import React from "react";
import { Link } from "react-router-dom";

const Serie = (props) => {
    const { serie } = props;

    return (
        <div className="serie">
            <Link to={`/series/${serie.id}`}>
                {serie.poster_path ? (
                    <img src={`https://image.tmdb.org/t/p/w300${serie.poster_path}`} alt="poster" />
                ) : serie.profile_path ? (
                    <img src={`https://image.tmdb.org/t/p/w300${serie.profile_path}`} alt="poster" />
                ) : (
                    <img src="../../../../../images/No-Image-Placeholder.svg" alt="poster" />
                )}
                <div className="conteneur">
                    <ul>
                        <li>{serie.name}</li>
                        {serie.number_of_seasons ? (<><li>
                            <span> Number of seasons :  </span>
                            <span> {serie.number_of_seasons}</span>
                        </li></>) : (<></>)}
                    </ul>
                </div>
            </Link>
        </div>
    )
}

export default Serie;