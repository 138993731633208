import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "./Card";
import CardLong from "./CardLong";
import SearchBar from "./SearchBar";
import { useParams } from "react-router-dom";
import Pagination from "./Pagination";

const SearchMovie = () => {

    const PageSize = 20;
    const [selectedPage, setSelectedPage] = useState(1);
    const [TotalCount, setTotalCount] = useState([]);
    const [data, setData] = useState([]);
    const [totalResultsAll, setTotalResultsAll] = useState([]);
    const [totalResultsMovie, setTotalResultsMovie] = useState([]);
    const [totalResultsTv, setTotalResultsTv] = useState([]);
    const [totalResultsPerson, setTotalResultsPerson] = useState([]);
    const { query } = useParams();
    const URL = `https://api.addictmovies.japellerin.ca/api/search/multi/${query}/${selectedPage}`;


    useEffect(() => {
        axios.get(URL).then((res) => setData(res.data.results));
    }, [URL]);

    useEffect(() => {
        axios.get(URL).then((res) => setTotalCount(res.data.total_results));
    }, [URL]);

    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/search/multi/${query}/${selectedPage}`).then((res) => setTotalResultsAll(res.data.total_results));
    }, [query]);

    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/search/movie/${query}/${selectedPage}`).then((res) => setTotalResultsMovie(res.data.total_results));
    }, [query]);

    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/search/tv/${query}/${selectedPage}`).then((res) => setTotalResultsTv(res.data.total_results));
    }, [query]);

    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/search/person/${query}/${selectedPage}`).then((res) => setTotalResultsPerson(res.data.total_results));
    }, [query]);

    return (
        <div className="searchMovie">
            <SearchBar />
            {query && (<h2>Results</h2>)}
            {query && (<ul className="results">
                <li className="results-classes"><h3>Movies</h3><li>{totalResultsMovie}</li></li>
                <li className="results-classes"><h3>Tv Shows</h3><li>{totalResultsTv}</li></li>
                <li className="results-classes"><h3>People</h3><li>{totalResultsPerson}</li></li>
                <li className="results-classes"><h3>Keywords</h3><li>{totalResultsAll}</li></li>
            </ul>)}
            <div className="resultsContainer">
                {query && (<ul className="searchMovie-list">
                    <div className="desktop-list">
                        {data.map((c) => (
                            <CardLong card={c} key={c.id} />
                        ))}
                    </div>
                    <div className="mobile-list">
                        {data.map((c) => (
                            <Card card={c} key={c.id} />
                        ))}
                    </div>
                </ul>)}
                <Pagination
                    className="pagination-bar"
                    currentPage={selectedPage}
                    totalCount={TotalCount}
                    pageSize={PageSize}
                    onPageChange={page => setSelectedPage(page)}
                />
            </div>
        </div>
    )
}

export default SearchMovie;