import React, { useState, useEffect } from "react";
import axios from 'axios';
import Trailer from "./Trailer";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import Slider from "react-slick";

const LastestTrailers = () => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const getTrailers = async () => {
            const trending = await axios.get("https://api.addictmovies.japellerin.ca/api/trending/week");
            const mediasData = await Promise.all(trending.data.results.map(async (res) => {
                const medias = await axios.get(`https://api.addictmovies.japellerin.ca/api/details/${res.media_type}/${res.id}`);

                return medias.data
            }));

            setVideos(mediasData);
        };

        getTrailers();
    }, []);


    console.log(videos);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        responsive: [
            {
                breakpoint: 1850,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1575,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1310,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1042,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className="lastestTrailers">
            {<ul className="lastestTrailers-list">
                <h2>Latest Trailers</h2>
                <Slider  {...settings}>
                    {videos.map((v) => (
                        <Trailer videos={v} key={v.id} />

                    ))}
                </Slider>

            </ul>}

        </div>
    )
}

export default LastestTrailers;