import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
    const { card } = props;

    return (
        <div className="card">
            <Link to={`/${card.runtime ? "movies" : card.number_of_seasons ? "series" : "people"}/${card.id}`}>
                {card.poster_path ? (
                    <img src={`https://image.tmdb.org/t/p/w300${card.poster_path}`} alt="poster" />
                ) : card.profile_path ? (
                    <img src={`https://image.tmdb.org/t/p/w300${card.profile_path}`} alt="poster" />
                ) : (
                    <img src="../../../../../images/No-Image-Placeholder.svg" alt="poster" />
                )}
                <div className="conteneur">
                    <ul>
                        <li>{card.title ? card.title : card.name}</li>
                        {card.runtime ? (<><li>
                            <span> Duration :  </span>
                            <span> {Math.floor(card.runtime / 60)}h {card.runtime % 60}m</span>
                        </li></>) : (<><li>
                            <span> Nb of seasons :  </span>
                            <span> {card.number_of_seasons}</span>
                        </li></>)}
                    </ul>
                </div>
            </Link>
        </div>
    )
}

export default Card;