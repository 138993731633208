import React from "react";
import HomeHeader from "../components/HomeHeader";
import PopularMovies from "../components/PopularMovies";
import PopularSeries from "../components/PopularSeries";
import LastestTrailers from "../components/LastestTrailers";
import TrendingMovies from "../components/TrendingMovies";
import SearchBar from "../components/SearchBar";

const Home = () => {
    return (
        <div className="popular">
            <HomeHeader />
            <SearchBar />
            <PopularMovies />
            <PopularSeries />
            <TrendingMovies />
            <LastestTrailers />
        </div>
    )
}

export default Home;