import React, { useState, useEffect } from "react";
import axios from 'axios';
import Serie from "./Serie";
import Movie from "./Movie";
import Pagination from "./Pagination";
import { useParams } from "react-router-dom";
import CategoriesSorted from "./CategoriesSorted";

const MoviesCategories = () => {
    const PageSize = 20;
    const [selectedPage, setSelectedPage] = useState(1);
    const [TotalCount, setTotalCount] = useState([]);
    const [genreId, setGenreId] = useState("");
    const [genreName, setGenreName] = useState("All");
    const { sort } = useParams();
    const { sortedby } = useParams();
    const { genre } = useParams();
    const { dateFrom } = useParams();
    const { dateTo } = useParams();
    const [mediaSorted, setMediaSorted] = useState([]);
    const URL = `https://api.addictmovies.japellerin.ca/api/discover/${sort}/${sortedby}${genre ? "/" + genre : ""}${dateTo ? "/" + dateTo : ""}${dateFrom ? "/" + dateFrom : ""}/${selectedPage}`;

    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/discover/${sort}`).then((res) => setGenreId(res.data.genres))
    }, [sort]);

    useEffect(() => {
        axios.get(URL).then((res) => setMediaSorted(res.data.results))
    }, [URL]);

    useEffect(() => {
        axios.get(URL).then((res) => setTotalCount(res.data.total_results))
    }, [URL]);

    // useEffect(() => {
    //     genre ? genreId.map((g) => (g.id == genre ? setGenreName(g.name) : null)) : setGenreName("All");
    // }, [genre]);


    return (
        <div className="moviesCategories">
            <h2>Choose a category</h2>
            <div className="moviesCategories-container">
                <CategoriesSorted />
                <div className="moviesCategories-sorted">
                    {/* <h3>{genreName}</h3> */}
                    {<ul className="moviesCategories-list">
                        {mediaSorted.map((m) => (
                            sort == "movie" ? (<Movie movie={m} key={m.id} />) : (<Serie serie={m} key={m.id} />)

                        ))}
                    </ul>}
                </div>
            </div>
            <Pagination
                className="pagination-bar"
                currentPage={selectedPage}
                totalCount={TotalCount}
                pageSize={PageSize}
                onPageChange={page => setSelectedPage(page)}
            />
        </div>
    );
}

export default MoviesCategories;