import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";

const PersonHeader = () => {
    const { id } = useParams();
    const category = "person";
    const [details, setDetails] = useState([]);
    const [genres, setGenres] = useState([]);


    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/details/${category}/${id}`).then((res) => setDetails(res.data))
    }, []);

    return (
        <div className="personHeader">
            <div className="personPoster">
                {details.profile_path ? (
                    <img src={`https://image.tmdb.org/t/p/original${details.profile_path}`} alt="poster" />
                ) : (
                    <img src="../images/No-Image-Placeholder.svg" alt="poster" />
                )}
            </div>
            <div className="personData">
                <h2>{details.name}</h2>
                <h3>Biography :</h3>
                <p>{details.biography}</p>

            </div>
        </div>
    )
}

export default PersonHeader;