import React, { useState, useEffect } from "react";
import axios from 'axios';
import Actor from "./Actor";
import Pagination from "./Pagination";

const PeoplePopular = () => {
    const PageSize = 20;
    const [selectedPage, setSelectedPage] = useState("1");
    const [TotalCount, setTotalCount] = useState([]);
    // const [selectedPage, setSelectedPage] = useState("1");
    const [peopleSorted, setPeopleSorted] = useState([]);
    const URL = `https://api.addictmovies.japellerin.ca/api/person/popular/${selectedPage}`;


    useEffect(() => {
        axios.get(URL).then((res) => setPeopleSorted(res.data.results))
    }, [selectedPage]);

    useEffect(() => {
        axios.get(URL).then((res) => setTotalCount(res.data.total_results))
    }, [selectedPage]);

    // useEffect(() => {
    //     axios.get(URL).then((res) => setPeopleSorted(res.data.total_results))
    // }, [selectedPage]);

    return (
        <div className="peoplePopular">
            <h2>Popular cast</h2>
            <div className="peoplePopular-container">
                {<ul className="peoplePopular-list">
                    {peopleSorted.map((a) => (
                        <Actor actor={a} key={a.id} />

                    ))}

                </ul>}

            </div>
            <Pagination
                className="pagination-bar"
                currentPage={selectedPage}
                totalCount={TotalCount}
                pageSize={PageSize}
                onPageChange={page => setSelectedPage(page)}
            />
        </div>
    );
}

export default PeoplePopular;