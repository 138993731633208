import React from "react";
import Logo from "./Logo";

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="column1">
                    <Logo />
                    <div className="about">
                        <p>This project has been made for learning purpose in order to collaborate and take part in the making of a web site that involves programmation, web integration and design skills.</p>
                    </div>
                    <p>© All rights reserved</p>
                </div>
                <div className="column2">
                    <h2>Our Team</h2>
                    <div className="team">
                        <p>Olivier Amyot-Ladouceur - Web Designer</p>
                        <p>Joseph-Antony Pellerin - Programmer</p>
                        <p>Louis-Pier Gignac - Web Integrator</p>
                        <p>Mohamed Saleck N'tahah - Web Integrator</p>
                    </div>
                    <h2>Follow Us</h2>
                    <div className="follow">
                        <a href="https://www.linkedin.com/in/olivier-amyot-ladouceur-914797232/"
                            target='_blank'
                            rel="noopener">
                            <img src="../../../../../images/oli.jpg" alt="Olivier" />
                        </a>
                        <a href="https://www.linkedin.com/in/japellerin/"
                            target='_blank'
                            rel="noopener">
                            <img src="../../../../../images/josephantony.jpg" alt="Joseph-Antony" />
                        </a>
                        <a href="https://www.linkedin.com/in/louis-pier-gignac-79b07b242/"
                            target='_blank'
                            rel="noopener">
                            <img src="../../../../../images/louispier.jpg" alt="Louis-Pier" />
                        </a>
                        <a href="https://www.linkedin.com/in/mohamed-saleck-ntahah-827546241/"
                            target='_blank'
                            rel="noopener">
                            <img src="../../../../../images/mohamed.jpg" alt="Mohamed" />
                        </a>
                    </div>
                </div>
                <div className="column3">
                    <h2>Social Media</h2>
                    <div className="social-media">
                        <a href="#">
                            <img src="../../../../../images/facebook.svg" alt="facebook" />
                        </a>
                        <a href="#">
                            <img src="../../../../../images/instagram.svg" alt="instagram" />
                        </a>
                        <a href="#">
                            <img src="../../../../../images/linkedin.svg" alt="youtube" />
                        </a>
                    </div>
                    <h2>Leave A Comment</h2>
                    <div className="email">
                        <p><a href="mailto:info.olivier.amyot.l@gmail.com">info.olivier.amyot.l@gmail.com</a></p>
                        <p><a href="mailto:joseph_pel@me.com">joseph_pel@me.com</a></p>
                        <p><a href="mailto:lougignac1@gmail.com">lougignac1@gmail.com</a></p>
                        <p><a href="mailto:msaleck10@yahoo.fr">msaleck10@yahoo.fr</a></p>
                    </div>
                </div>
            </div>
            <div className="footerMobile">
                <Logo />
                <div className="column1">
                    <div className="ourTeam">
                        <h2>Our Team</h2>
                        <div className="team">
                            <p>Olivier Amyot-Ladouceur - Web Designer</p>
                            <p>Joseph-Antony Pellerin - Programmer</p>
                            <p>Louis-Pier Gignac - Web Integrator</p>
                            <p>Mohamed Saleck N'tahah - Web Integrator</p>
                        </div>
                    </div>
                    <div className="followUs">
                        <h2>Follow Us</h2>
                        <div className="follow">
                            <a href="https://www.linkedin.com/in/olivier-amyot-ladouceur-914797232/"
                                target='_blank'
                                rel="noopener">
                                <img src="../../../../../images/oli.jpg" alt="Olivier" />
                            </a>
                            <a href="https://www.linkedin.com/in/japellerin/"
                                target='_blank'
                                rel="noopener">
                                <img src="../../../../../images/josephantony.jpg" alt="Joseph-Antony" />
                            </a>
                            <a href="https://www.linkedin.com/in/louis-pier-gignac-79b07b242/"
                                target='_blank'
                                rel="noopener">
                                <img src="../../../../../images/louispier.jpg" alt="Louis-Pier" />
                            </a>
                            <a href="https://www.linkedin.com/in/mohamed-saleck-ntahah-827546241/"
                                target='_blank'
                                rel="noopener">
                                <img src="../../../../../images/mohamed.jpg" alt="Mohamed" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="column2">
                    <h2>Leave A Comment</h2>
                    <div className="email">
                        <p><a href="mailto:info.olivier.amyot.l@gmail.com">info.olivier.amyot.l@gmail.com</a></p>
                        <p><a href="mailto:joseph_pel@me.com">joseph_pel@me.com</a></p>
                        <p><a href="mailto:lougignac1@gmail.com">lougignac1@gmail.com</a></p>
                        <p><a href="mailto:msaleck10@yahoo.fr">msaleck10@yahoo.fr</a></p>
                    </div>
                    <h2>Social Media</h2>
                    <div className="social-media">
                        <a href="#">
                            <img src="../../../../../images/facebook.svg" alt="facebook" />
                        </a>
                        <a href="#">
                            <img src="../../../../../images/instagram.svg" alt="instagram" />
                        </a>
                        <a href="#">
                            <img src="../../../../../images/linkedin.svg" alt="youtube" />
                        </a>
                    </div>
                </div>
                <div className="column3">
                    <div className="about">
                        <p>This project has been made for learning purpose in order to collaborate and take part in the making of a web site that involves programmation, web integration and design skills.</p>
                    </div>
                    <p>© All rights reserved</p>
                </div>
            </div>
        </>
    )
}

export default Footer;