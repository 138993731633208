import React, { useState, useEffect } from "react";
import axios from 'axios';
import Serie from "./Serie";
import Slider from "react-slick";

const PopularSeries = () => {
    const [details, setDetails] = useState([]);

    useEffect(() => {
        const getDetails = async () => {
            const popular = await axios.get("https://api.addictmovies.japellerin.ca/api/popular/tv");
            const mediasData = await Promise.all(popular.data.results.map(async (res) => {
                const medias = await axios.get(`https://api.addictmovies.japellerin.ca/api/details/tv/${res.id}`);

                return medias.data
            }));

            setDetails(mediasData);
        };

        getDetails();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 5,
        arrows: true,
        responsive: [
            {
                breakpoint: 1850,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 1575,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 1110,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    return (
        <div className="popularSeries">
            <h2>Popular Series</h2>
            {<ul className="popularSeries-list">
                <Slider  {...settings}>
                    {details.map((s) => (
                        <Serie serie={s} key={s.id} />

                    ))}
                </Slider>

            </ul>}

        </div>
    )
}

export default PopularSeries;