import React from "react";
import { Link } from "react-router-dom";
import CategoriesSorted from "../components/CategoriesSorted";
import PopularMoviesCategories from "../components/PopularMoviesCategories";
import PopularSeriesCategories from "../components/PopularSeriesCategories";
import ComedyCategory from "../components/ComedyCategory";
import HorrorCategory from "../components/HorrorCategory";

const Categories = () => {
    return (
        <div className="categories">
            <div className="moviesCategories">
                <h2>Choose a category</h2>
                <div className="moviesCategories-container">
                    <CategoriesSorted />
                    <div className="moviesCategories-carrousels">
                        <PopularMoviesCategories />
                        <PopularSeriesCategories />
                        <HorrorCategory />
                        <ComedyCategory />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Categories;