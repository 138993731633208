import React, { useState, useEffect } from "react";
import axios from 'axios';

const HomeHeader = () => {
    const [trending, setTrending] = useState([]);
    const [details, setDetails] = useState([]);

    useEffect(() => {
        axios.get("https://api.addictmovies.japellerin.ca/api/trending/week").then((res) => setTrending(res.data.results[0]))
    }, []);

    useEffect(() => {
        const getTrailers = async () => {
            const trending = await axios.get("https://api.addictmovies.japellerin.ca/api/trending/week");
            const mediasData = await Promise.all(trending.data.results.map(async (res) => {
                const medias = await axios.get(`https://api.addictmovies.japellerin.ca/api/details/${res.media_type}/${res.id}`);

                return medias.data
            }));

            setDetails(mediasData[0]);
        };

        getTrailers();
    }, []);

    return (
        <div className="homeHeader" style={{
            backgroundImage:
                `linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73)),url('https://image.tmdb.org/t/p/original${trending.backdrop_path}')`
        }} >
            <div className="homeHeader-text">
                <div className="slogan">
                    <h1>The more you want</h1>
                    <h1>The more you get</h1>
                </div>
                <h2>{trending.title ? trending.title : trending.name}</h2>
                {details.runtime ? (<>
                    <h3><span> Duration :  </span>
                        <span> {Math.floor(details.runtime / 60)}h {details.runtime % 60}m</span></h3>
                </>) : (<>
                    <h3><span> Number of seasons :  </span>
                        <span> {details.number_of_seasons}</span></h3>
                </>)}
                <a
                    class='official website'
                    href={details.homepage}
                    target='_blank'
                    rel="noopener"
                ><button>Watch Now</button></a>

            </div>

        </div>
    )
}

export default HomeHeader;