import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import TrailerM from "./TrailerM";

const MovieTrailer = () => {
    const { id } = useParams();
    const category = "movie";
    const [trailer, setTrailer] = useState([]);


    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/details/${category}/${id}`).then((res) => setTrailer(res.data.videos.results))
    }, [id]);

    return (
        <div className="movieTrailer">
            <h2>Watch the Trailer</h2>
            {<ul className="movieTrailer-list">
                {[...trailer].reverse().slice(0, 2).map((v) => (
                    <TrailerM videos={v} key={v.id} />

                ))}

            </ul>}
        </div>
    )
}

export default MovieTrailer;