import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";

const SerieHeader = () => {
    const { id } = useParams();
    const category = "tv";
    const [details, setDetails] = useState([]);
    const [genres, setGenres] = useState([]);


    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/details/${category}/${id}`).then((res) => setDetails(res.data))
    }, [id]);

    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/details/${category}/${id}`).then((res) => setGenres(res.data.genres))
    }, [id]);

    return (
        <div className="serieHeader" style={{
            backgroundImage:
                `linear-gradient(to bottom, rgba(129, 129, 129, 0.42), rgba(0, 0, 0, 0.82)),url('https://image.tmdb.org/t/p/original${details.backdrop_path}')`
        }} >
            <div className="seriePoster">
                {details.poster_path ? (
                    <img src={`https://image.tmdb.org/t/p/original${details.poster_path}`} alt="poster" />
                ) : (
                    <img src="../images/No-Image-Placeholder.svg" alt="poster" />
                )}
            </div>
            <div className="serieData">
                <h2>{details.name}</h2>
                <p>{details.release_date}</p>
                <div className="specs">
                    <p><span>Genre :  </span>
                        {genres.map((genre, index) => (
                            <span key={genre.id}>{(index ? ', ' : '') + genre.name}</span>

                        ))}</p>
                    <p><span> Number of seasons :  </span>
                        <span> {details.number_of_seasons}</span></p>
                </div>
                <div className="synopsis">
                    <h3>Synopsis :</h3>
                    <p>{details.overview}</p>
                </div>
                <h3>Rating :</h3>
                <div class="progress">
                    <div class="progress-in" style={{
                        width: `${Math.round(details.vote_average * 10)}%`
                    }}></div>
                    <div class="competence-pourcent">
                        {Math.round(details.vote_average * 10)}%
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SerieHeader;