import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from 'axios';
import Movie from "./Movie";
import { Link } from "react-router-dom";

const ComedyCategory = () => {
    const [comedy, setComedy] = useState([]);
    const [details, setDetails] = useState([]);

    useEffect(() => {
        axios.get("https://api.addictmovies.japellerin.ca/api/discover/movie/popularity/35/1").then((res) => setComedy(res.data.results))
    }, [])

    useEffect(() => {
        const getDetails = async () => {
            const comedy = await axios.get("https://api.addictmovies.japellerin.ca/api/discover/movie/popularity.desc/35/1");
            const mediasData = await Promise.all(comedy.data.results.map(async (res) => {
                const medias = await axios.get(`https://api.addictmovies.japellerin.ca/api/details/movie/${res.id}`);

                return medias.data
            }));

            setDetails(mediasData);
        };

        getDetails();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        responsive: [
            {
                breakpoint: 1850,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 1575,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 1160,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    return (
        <div className="comedyCategory">
            <h2>Comedy</h2>
            {<ul className="comedyCategory-list">
                <Slider  {...settings}>
                    {details.map((m) => (
                        <Movie movie={m} key={m.id} />

                    ))}
                </Slider>

            </ul>}
            <Link to='/categories/movie/popularity.desc/35'><h3>View All</h3></Link>
        </div>
    )
}

export default ComedyCategory;