import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Navigation from "./components/Navigation";
import Home from "./pages/Home";
import Categories from "./pages/Categories";
import CategoriesAll from "./pages/CategoriesAll";
import Movies from "./pages/Movies";
import Series from "./pages/Series";
import People from "./pages/People";
import Person from "./pages/Person";
import Casting from "./pages/Casting";
import Search from "./pages/Search";
import About from "./pages/About";
import Footer from "./components/Footer";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navigation />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/categories" exact component={Categories} />
        <Route path="/categories/:sort/:sortedby" exact component={CategoriesAll} />
        <Route path="/categories/:sort/:sortedby/:genre" exact component={CategoriesAll} />
        <Route path="/categories/:sort/:sortedby/:dateTo/:dateFrom" exact component={CategoriesAll} />
        <Route path="/categories/:sort/:sortedby/:genre/:dateTo/:dateFrom" exact component={CategoriesAll} />
        <Route path="/movies/:id" exact component={Movies} />
        <Route path="/:category/:id/cast" exact component={Casting} />
        <Route path="/series/:id" exact component={Series} />
        <Route path="/people" exact component={People} />
        <Route path="/people/:id" exact component={Person} />
        <Route path="/search" exact component={Search} />
        <Route path="/search/:query" exact component={Search} />
        <Route path="/about" exact component={About} />
      </Switch>
      <Footer />
    </BrowserRouter>
  )
}

export default App;