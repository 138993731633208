import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

const SearchBar = () => {
    const [data, setData] = useState([]);
    const [keywords, setKeywords] = useState("");
    const history = useHistory();
    const URL = `https://api.addictmovies.japellerin.ca/api/search/multi${keywords ? `/${keywords}/1` : ""}`;

    useEffect(() => {
        axios.get(URL).then((res) => setData(res.data.results));
    }, [keywords]);

    const onSearch = (event) => {
        setKeywords(event.target.value);
    }

    const onSearchSubmit = () => {
        history.push(`/search/${keywords}`);
        setKeywords("");
    }

    const linkStyle = {
        margin: ".25rem .5rem",
        padding: "0",
        textDecoration: "none",
        color: '$color-4'
    };

    return (
        <div className="searchBar">
            <div className="input-container">
                <h2>Looking for something?</h2>
                <h3>We have all the movies and the</h3>
                <h3>TV shows you might be looking for.</h3>
                <div className="search-form">
                    <input
                        className="input"
                        placeholder="Search"
                        value={keywords}
                        onChange={onSearch}>
                    </input>
                    <div className="dropdown">
                        {keywords ? (
                            <ul>
                                {data.map((movie) => (
                                    <Link className="dropdown-row" to={`/${movie.media_type == "movie" ? "movies" : movie.media_type == "tv" ? "series" : "people"}/${movie.id}`} style={linkStyle}>
                                        <span>{movie.title ? movie.title : movie.name}</span>
                                    </Link>
                                ))}
                            </ul>
                        ) : ("")}
                    </div>
                    <input type="submit" value="Search" className="btn" onClick={onSearchSubmit} />
                </div>
            </div>
        </div>
    )
}

export default SearchBar;