import React from "react";
import { Link } from "react-router-dom";

const CardLong = (props) => {
    const { card } = props;
    return (
        <div className="cardLong">
            <Link to={`/${card.media_type == "movie" ? "movies" : card.media_type == "tv" ? "series" : card.media_type == "person" ? "people" : "movies"}/${card.id}`}>
                {card.poster_path ? (
                    <img src={`https://image.tmdb.org/t/p/w300${card.poster_path}`} alt="poster" />
                ) : card.profile_path ? (
                    <img src={`https://image.tmdb.org/t/p/w300${card.profile_path}`} alt="poster" />
                ) : (
                    <img src="../../../../../images/No-Image-Placeholder.svg" alt="poster" />
                )}
            </Link>
            <div className="conteneur">
                <ul>
                    <li>{card.title ? card.title : card.name}</li>
                    <li>{card.release_date ? card.release_date : card.first_air_date}</li>
                    <li>{card.overview}</li>
                </ul>
            </div>
        </div>
    )
}

export default CardLong;