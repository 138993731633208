import React, { useState, useEffect } from "react";

const TrailerM = (props) => {
    const { videos } = props;

    return (
        <div className="trailerM">
            <div className="trailerDesktop">
                <iframe width="852" height="500" src={videos.site == "YouTube" ? `https://www.youtube.com/embed/${videos.key}` : videos.site == "Vimeo" ? `https://vimeo.com/${videos.key}` : null} title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="trailerMobile">
                <iframe width="308" height="206" src={videos.site == "YouTube" ? `https://www.youtube.com/embed/${videos.key}` : videos.site == "Vimeo" ? `https://vimeo.com/${videos.key}` : null} title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <div className="conteneur">
                {videos.title || videos.name ?
                    <li>{videos.title ? videos.title : videos.name}</li> : null
                }
            </div>
        </div>
    )
}

export default TrailerM;