import React from "react";
import MovieHeader from "../components/MovieHeader";
import MovieCasting from "../components/MovieCasting";
import MovieRelated from "../components/MovieRelated";
import MovieTrailer from "../components/MovieTrailer";

const Movies = () => {
    return (
        <div className="movies">
            <MovieHeader />
            <MovieTrailer />
            <MovieCasting />
            <MovieRelated />
        </div>
    )
}

export default Movies;