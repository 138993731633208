import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';

const Navigation = () => {
    const [extendNavbar, setExtendNavbar] = useState(false);

    return (
        <div id='navbar' className={extendNavbar ? "navbar extendedNavbar" : "navbar"}>
            <div className='innerContainer'>
                <div className='leftContainer'>
                    <NavLink exact to="/" end className={({ isActive }) =>
                        isActive ? "nav-active" : undefined}>
                        <Logo />
                    </NavLink>
                </div>
                <div className="rightContainer">
                    <div className='navLinks'>
                        <button onClick={() => {
                            setExtendNavbar((curr) => !curr);
                        }}>{extendNavbar ? <>&#10005;</> : <>&#8801;</>}</button>
                        <NavLink exact to="/" activeClassName='nav-active'>
                            Home
                        </NavLink>
                        <NavLink exact to="/categories" activeClassName='nav-active'>
                            Categories
                        </NavLink>
                        <NavLink exact to="/people" activeClassName='nav-active'>
                            People
                        </NavLink>
                        <NavLink exact to="/search" activeClassName='nav-active'>
                            Search
                        </NavLink>
                        <NavLink exact to="/about" activeClassName='nav-active'>
                            About
                        </NavLink>
                    </div>
                </div>
            </div>
            {extendNavbar && <div className="extendedContainer">
                <NavLink exact to="/" activeClassName='nav-active' onClick={() => {
                    setExtendNavbar((curr) => !curr);
                }}>
                    Home
                </NavLink>
                <NavLink exact to="/categories" activeClassName='nav-active' onClick={() => {
                    setExtendNavbar((curr) => !curr);
                }}>
                    Categories
                </NavLink>
                <NavLink exact to="/people" activeClassName='nav-active' onClick={() => {
                    setExtendNavbar((curr) => !curr);
                }}>
                    People
                </NavLink>
                <NavLink exact to="/search" activeClassName='nav-active' onClick={() => {
                    setExtendNavbar((curr) => !curr);
                }}>
                    Search
                </NavLink>
                <NavLink exact to="/about" activeClassName='nav-active' onClick={() => {
                    setExtendNavbar((curr) => !curr);
                }}>
                    About
                </NavLink>
            </div>}
        </div>
    )
}

export default Navigation;