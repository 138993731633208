import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import Actor from "./Actor";

const CastingAll = () => {
    const { id } = useParams();
    const { category } = useParams();
    const [casting, setCasting] = useState([]);
    const [crew, setCrew] = useState([]);


    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/details/${category == "movies" ? "movie" : "tv"}/${id}`).then((res) => setCasting(res.data.credits.cast))
    }, [id]);

    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/details/${category == "movies" ? "movie" : "tv"}/${id}`).then((res) => setCrew(res.data.credits.crew))
    }, [id]);


    return (
        <div className="castingAll">
            <h2>Casting</h2>
            {<ul className="movieCasting-list">
                {casting.map((a) => (
                    <Actor actor={a} key={a.id} />

                ))}
            </ul>}
            <h2>Crew</h2>
            {<ul className="movieCrew-list">
                {crew.map((a) => (
                    <Actor actor={a} key={a.id} />

                ))}
            </ul>}
        </div>
    )
}

export default CastingAll;