import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import Movie from "./Movie";
import Slider from "react-slick";

const MovieRelated = () => {
    const { id } = useParams();
    const category = "movie";
    const [related, setRelated] = useState([]);


    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/details/${category}/${id}`).then((res) => setRelated(res.data.similar.results))
    }, [id]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: true,
        responsive: [
            {
                breakpoint: 1850,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1575,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1310,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1042,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className="movieRelated">
            <h2>Related Movies</h2>
            {<ul className="movieRelated-list">
                <Slider  {...settings}>
                    {related.map((m) => (
                        <Movie movie={m} key={m.id} />

                    ))}
                </Slider>

            </ul>}
        </div>
    )
}

export default MovieRelated;