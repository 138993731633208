import React from "react";
import SerieHeader from "../components/SerieHeader";
import SerieTrailer from "../components/SerieTrailer";
import SerieCasting from "../components/SerieCasting";
import SerieRelated from "../components/SerieRelated";

const Series = () => {
    return (
        <div className="series">
            <SerieHeader />
            <SerieTrailer />
            <SerieCasting />
            <SerieRelated />
        </div>
    )
}

export default Series;