import React from "react";

const About = () => {
    return (
        <div className="aboutus">
            <div className="desc">
                <h1>A few words about us</h1>
                <p className="us">We like to call ourself the Fantastic 4. Even though it's sort of a joke, we believe that we are actually fantastic. Our strenght resides in the fact that we all have a different background.
                    It is our many points of view that makes us an awesome team. We built this website in cooperation with one another
                    in order to combine our talents. We hope that you'll enjoy it as much as we had fun making it.
                </p>

                <h1>The Fantastic 4 members</h1>
            </div>
            <div className="members">
                <div className="row2">
                    <div className="photo">
                        <img src="../images/oli.jpg"></img>
                    </div>
                    <div className="text">
                        <h2 className="name"> Olivier Amyot-Ladouceur</h2>
                        <h3 className="title"> Web Designer</h3>
                        <p className="presentation">Olivier is an artist at heart. What drives him is turning the most mundane topics
                            into the most beautiful object you'll ever see. Fortunatly for him, the subject of this website was a gripping one! Designing this website was really enjoyable.
                            <br />
                            <br />
                            Olivier's favorite movie is <b>The shape of water</b> directed by Guillermo Del Toro and released in 2017. Needless to say, that guy is a dreamer.
                        </p>
                    </div>
                </div>
                <div className="row2">
                    <div className="photo">
                        <img src="../images/josephantony.jpg"></img>
                    </div>
                    <div className="text">
                        <h2 className="name"> Joseph-Antony Pellerin</h2>
                        <h3 className="title"> Programmer</h3>
                        <p className="presentation">Joseph-Antony has always loved expressing his creativity and working his brain out. For as long as he can remembers, he never found a way to conciliate the two of them. That until he found out about web development. Programming this website was a revelation for him! At last, he can express those two essential part of himself.
                            <br />
                            <br />
                            His favorite movie is <b>Inception</b> directed by Christopher Nolan and released in 2010. You won’t be surprised if we say that this guy likes to be surprised by his entertainment.
                        </p>
                    </div>
                </div>
                <div className="louisPier">
                    <div className="row2">
                        <div className="photo">
                            <img src="../images/louispier.jpg"></img>
                        </div>
                        <div className="text">
                            <h2 className="name"> Louis-Pier Gignac </h2>
                            <h3 className="title"> Web Integrator </h3>
                            <p className="presentation">Louis-Pier has been passionate about movies for as long as he can remember. Growing up, watching movies, and going to the movie theater with his family was a way to spend time with them while having fun. Doing this project was an effective way to reconcile his love for cinema with his enthusiasm towards Web development.
                                <br />
                                <br />
                                His favorite movie is <b>Interstellar</b> directed by Christopher Nolan which came out in 2014.</p>
                        </div>
                    </div>
                </div>
                <div className="row2">
                    <div className="photo">
                        <img src="../images/mohamed.jpg"></img>
                    </div>
                    <div className="text">
                        <h2 className="name"> Mohamed Saleck N'tahah</h2>
                        <h3 className="title"> Web Integrator</h3>
                        <p className="presentation">Mohamed Saleck is a financial analyst at first. Recently, he decided to challenge himself by learning web development. His new skills could be useful to fulfil a financial purpose. He was pleased to work on the integration part of this website.
                            <br />
                            <br />
                            Mohamed’s favorite movie is <b>The Intouchables</b> directed by Olivier Nakache and Eric Toledano. The friendship between the two main caracters got him.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;