import React from "react";
import MoviesCategories from "../components/MoviesCategories";

const CategoriesAll = () => {
    return (
        <div className="categoriesAll">
            <MoviesCategories />
        </div>
    )
}

export default CategoriesAll;