import React from "react";
import { Link } from "react-router-dom";

const Actor = (props) => {
    const { actor } = props;

    return (
        <div className="actor">
            <Link to={`/people/${actor.id}`}>
                {actor.profile_path ? (
                    <img src={`https://image.tmdb.org/t/p/w300${actor.profile_path}`} alt="poster" />
                ) : (
                    <img src="../../../../../images/No-Image-Placeholder.svg" alt="poster" />
                )}
                <div className="conteneur">
                    <ul>
                        <li>{actor.name}</li>
                        <li>{actor.character ? actor.character : actor.job}</li>
                    </ul>
                </div>
            </Link>
        </div>
    )
}

export default Actor;