import React from "react";
import { Link } from "react-router-dom";
// import noImgPlaceholder from '../img/noImagePlaceholder.svg';

const Movie = (props) => {
    const { movie } = props;

    return (
        <div className="movie">
            <Link to={`/movies/${movie.id}`}>
                {movie.poster_path ? (
                    <img src={`https://image.tmdb.org/t/p/w300${movie.poster_path}`} alt="poster" />
                ) : movie.profile_path ? (
                    <img src={`https://image.tmdb.org/t/p/w300${movie.profile_path}`} alt="poster" />
                ) : (
                    <img src='../../../../../images/No-Image-Placeholder.svg' alt="poster" />
                )}
                <div className="conteneur">
                    <ul>
                        <li>{movie.title ? movie.title : movie.name}</li>
                        {movie.credit_id ? <li>{movie.character}</li> : null}
                        {movie.runtime ? (<><li>
                            <span> Duration :  </span>
                            <span> {Math.floor(movie.runtime / 60)}h {movie.runtime % 60}m</span>
                        </li></>) : (<></>)}
                    </ul>
                </div>
            </Link>
        </div>
    )
}

export default Movie;