import React from "react";
import PeoplePopular from "../components/PeoplePopular";

const People = () => {
    return (
        <div className="people">
            <PeoplePopular />
        </div>
    )
}

export default People;