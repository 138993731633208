import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Actor from "./Actor";

const SerieCasting = () => {
    const { id } = useParams();
    const category = "tv";
    const [casting, setCasting] = useState([]);


    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/details/${category}/${id}`).then((res) => setCasting(res.data.credits.cast))
    }, [id]);

    return (
        <div className="movieCasting">
            <h2>Casting</h2>
            {<ul className="movieCasting-list">
                {casting
                    .slice(0, 8)
                    .map((a) => (
                        <Actor actor={a} key={a.id} />

                    ))}
                <div className="actor">
                    <Link to={`/series/${id}/cast`}>
                        <div className="conteneurMore">
                            <ul>
                                <li>View more</li>
                                <li>&#8594;</li>
                            </ul>
                        </div>
                    </Link>
                </div>

            </ul>}
        </div>
    )
}

export default SerieCasting;