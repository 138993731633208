import React from "react";
import CastingAll from "../components/CastingAll";

const Casting = () => {
    return (
        <div className="casting">
            <CastingAll />
        </div>
    )
}

export default Casting;