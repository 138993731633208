import React, { useState, useEffect } from "react";
import axios from 'axios';
import Card from "./Card";
import Slider from "react-slick";

const TrendingMovies = () => {
    const [trending, setTrending] = useState([]);
    const [details, setDetails] = useState([]);
    const times = ["day", "week"];
    const [selectedTime, setSelectedTime] = useState("day");

    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/trending/${selectedTime}`).then((res) => setTrending(res.data.results))
    }, [selectedTime])

    useEffect(() => {
        const getDetails = async () => {
            const popular = await axios.get(`https://api.addictmovies.japellerin.ca/api/trending/${selectedTime}`);
            const mediasData = await Promise.all(popular.data.results.map(async (res) => {
                const medias = await axios.get(`https://api.addictmovies.japellerin.ca/api/details/${res.media_type}/${res.id}`);

                return medias.data
            }));

            setDetails(mediasData);
        };

        getDetails();
    }, [selectedTime]);

    const onClick = (e) => {
        setSelectedTime(e.target.value);
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: true,
        responsive: [
            {
                breakpoint: 1850,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 1575,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 1110,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    return (
        <div className="trendingMovies">
            <h2>Trending</h2>
            {times.map((time) => {
                return (
                    <button
                        value={time}
                        onClick={onClick}>
                        {time}
                    </button>
                )
            })}
            {<ul className="trendingMovies-list">
                <Slider  {...settings}>
                    {details.map((c) => (
                        <Card card={c} key={c.id} />

                    ))}
                </Slider>

            </ul>}

        </div>
    )
}

export default TrendingMovies;