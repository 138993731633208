import React from "react";
import SearchMovie from "../components/SearchMovie";

const Search = () => {
    return (
        <div className="search">
            <SearchMovie />
        </div>
    )
}

export default Search;