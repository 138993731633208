import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import Movie from "./Movie";
import Serie from "./Serie";

const Featuring = () => {
    const { id } = useParams();
    const [featuring, setFeaturing] = useState([]);

    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/details/person/${id}/combined_credits`).then((res) => setFeaturing(res.data.cast))
    }, [])

    return (
        <div className="featuring">
            <h2>Featuring</h2>
            {<ul className="featuring-list">
                {featuring.map((m) => (
                    m.media_type == "movie" ?
                        <Movie movie={m} key={m.id} /> :
                        <Serie serie={m} key={m.id} />

                ))}

            </ul>}

        </div>
    )
}

export default Featuring;