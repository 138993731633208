import React from "react";
import PersonHeader from "../components/PersonHeader";
import Featuring from "../components/Featuring";

const Person = () => {
    return (
        <div className="person">
            <PersonHeader />
            <Featuring />
        </div>
    )
}

export default Person;