import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const CategoriesSorted = () => {
    const [extendMenu, setExtendMenu] = useState(false);
    const history = useHistory();
    const sorts = [
        { id: "movie", name: "Movies" },
        { id: "tv", name: "TV Shows" },
    ];
    const [genres, setGenres] = useState([]);
    const sortBy = [
        { id: "popularity", name: "Popular" },
        { id: "vote_average", name: "Top rated" },
    ];
    const sc = [
        { id: ".asc", name: "Ascending" },
        { id: ".desc", name: "Descending" },
    ];
    const [cat, setCat] = useState("movie");
    const [genre, setGenre] = useState("");
    const [sortby, setSortBy] = useState("popularity");
    const [selectedSC1, setSelectedSC1] = useState(".desc");
    const [dateGte, setDateGte] = useState("");
    const [dateLte, setDateLte] = useState("");

    useEffect(() => {
        axios.get(`https://api.addictmovies.japellerin.ca/api/discover/${cat}`).then((res) => setGenres(res.data.genres))
    }, [cat]);

    const onCheckSort = (event) => {
        setCat(event.target.value);
    }

    const onCheckSortBy = (event) => {
        setSortBy(event.target.value);
        setDateLte("");
        setDateGte("");
    }

    const onCheckSC1 = (event) => {
        setSelectedSC1(event.target.value);
    }

    const onChangeFrom = (event) => {
        setSortBy("release_date")
        setDateLte(event.target.value);
    }

    const onChangeTo = (event) => {
        setSortBy("release_date")
        setDateGte(event.target.value);
    }

    const onChangeGenre = () => {
        setGenre(document.getElementById("genre").value);

    }

    const onClickGenre = () => {
        return history.push(`/categories/${cat}/${sortby}${selectedSC1}${genre ? "/" + genre : genre}${dateGte ? "/" + dateGte : dateGte}${dateLte ? "/" + dateLte : dateLte}`), [history];

    }

    const onClickGenreMenu = () => {
        setExtendMenu((curr) => !curr);
        return history.push(`/categories/${cat}/${sortby}${selectedSC1}${genre ? "/" + genre : genre}${dateGte ? "/" + dateGte : dateGte}${dateLte ? "/" + dateLte : dateLte}`), [history];

    }


    return (
        <div className='sort-categories-container'>
            <div className="sortMenuDesktop">
                <div className='sort-movie-tv'>
                    <ul>
                        {sorts.map((sort) => {
                            return (
                                <li>
                                    <input
                                        type="radio"
                                        name="sort"
                                        value={sort.id}
                                        checked={sort.id == cat}
                                        onChange={onCheckSort}></input>
                                    <label>{sort.name}</label>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className='sort-by'>
                    <ul>
                        {sortBy.map((sorted) => {
                            return (
                                <li>
                                    <input
                                        type="radio"
                                        name="sortBy"
                                        value={sorted.id}
                                        checked={sorted.id == sortby}
                                        onChange={onCheckSortBy}></input>
                                    <label>{sorted.name}</label>
                                </li>
                            )
                        })}
                    </ul>
                    <ul>
                        {sc.map((s) => {
                            return (
                                <li>
                                    <input
                                        type="radio"
                                        name="sc1"
                                        value={s.id}
                                        checked={s.id == selectedSC1}
                                        onChange={onCheckSC1}></input>
                                    <label>{s.name}</label>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className='sort-by-dates'>
                    <h4>Release Dates</h4>
                    <ul className="dates">
                        <form>
                            <li>
                                <label for="from">From:</label>
                                <input
                                    type="date"
                                    id="from"
                                    name="trip-start"
                                    value={dateLte ? dateLte : ""}
                                    min="1874-12-09"
                                    onChange={onChangeFrom}></input>
                            </li>
                            <li>
                                <label for="to">To:</label>
                                <input
                                    type="date"
                                    id="to"
                                    name="trip-end"
                                    value={dateGte ? dateGte : ""}
                                    min="1874-12-09"
                                    onChange={onChangeTo}></input>
                            </li>
                        </form>
                    </ul>

                </div>
                <div className='sort-genre'>
                    <h4>Genres</h4>
                    <select
                        name="genre"
                        id="genre"
                        onChange={onChangeGenre}>
                        <option
                            value="">
                            All
                        </option>
                        {genres.map((genre) => {
                            return (
                                <option
                                    value={genre.id}>
                                    {genre.name}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div className="btn-center">
                    <button onClick={onClickGenre}>Search</button>
                </div>
            </div>
            <div className="sortMenuMobile">
                <a onClick={() => {
                    setExtendMenu((curr) => !curr);
                }}>Filters</a>
                {extendMenu && <div className="extendedMenu">
                    <div className='sort-movie-tv'>
                        <ul>
                            {sorts.map((sort) => {
                                return (
                                    <li>
                                        <input
                                            type="radio"
                                            name="sort"
                                            value={sort.id}
                                            checked={sort.id == cat}
                                            onChange={onCheckSort}></input>
                                        <label>{sort.name}</label>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className='sort-by'>
                        <ul>
                            {sortBy.map((sorted) => {
                                return (
                                    <li>
                                        <input
                                            type="radio"
                                            name="sortBy"
                                            value={sorted.id}
                                            checked={sorted.id == sortby}
                                            onChange={onCheckSortBy}></input>
                                        <label>{sorted.name}</label>
                                    </li>
                                )
                            })}
                        </ul>
                        <ul>
                            {sc.map((s) => {
                                return (
                                    <li>
                                        <input
                                            type="radio"
                                            name="sc1"
                                            value={s.id}
                                            checked={s.id == selectedSC1}
                                            onChange={onCheckSC1}></input>
                                        <label>{s.name}</label>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className='sort-by-dates'>
                        <h4>Release Dates</h4>
                        <ul className="dates">
                            <form>
                                <li>
                                    <label for="from">From:</label>
                                    <input
                                        type="date"
                                        id="from"
                                        name="trip-start"
                                        value={dateLte ? dateLte : ""}
                                        min="1874-12-09"
                                        onChange={onChangeFrom}></input>
                                </li>
                                <li>
                                    <label for="to">To:</label>
                                    <input
                                        type="date"
                                        id="to"
                                        name="trip-end"
                                        value={dateGte ? dateGte : ""}
                                        min="1874-12-09"
                                        onChange={onChangeTo}></input>
                                </li>
                            </form>
                        </ul>

                    </div>
                    <div className='sort-genre'>
                        <h4>Genres</h4>
                        <select
                            name="genre"
                            id="genre"
                            onChange={onChangeGenre}>
                            <option
                                value="">
                                All
                            </option>
                            {genres.map((genre) => {
                                return (
                                    <option
                                        value={genre.id}>
                                        {genre.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="btn-center">
                        <button onClick={onClickGenreMenu}>Search</button>
                    </div>
                </div>}
            </div>
        </div>

    );
}

export default CategoriesSorted;